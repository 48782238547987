import { defineMessages } from 'react-intl';

const translations = {
  messages: {
    updateAvailableMessage: {
      id: 'app.messages.updateAvailableMessage',
      defaultMessage: 'A new version of the app is available',
      description: 'message when a new version of the app is available',
    },
    updateButtonAction: {
      id: 'app.messages.updateButtonAction',
      defaultMessage: 'Update',
      description: 'name of the update button',
    },
  },
};

export default defineMessages(translations);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, injectIntl } from 'react-intl';
import { setSelectedPrinter } from '../../modules/spooler/actions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  saveAccessToken,
  setShowAccessTokenModal,
  validateAccessToken,
} from '../../modules/authorization/actions';
import translations from './translations';

export class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      afostoAccessToken: '',
      openSetting: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { accessToken } = this.props;

    if (prevProps.accessToken !== accessToken) {
      this.loadAccessToken();
    }
  }

  loadAccessToken = () => {
    const { accessToken } = this.props;

    this.setState({
      afostoAccessToken: accessToken,
    });
  };

  handleChangeAccessToken = event => {
    const afostoAccessToken = event.target.value;
    const { onAccessTokenValidate } = this.props;

    this.setState({ afostoAccessToken });
    onAccessTokenValidate(afostoAccessToken);
  };

  handleSaveAccessToken = () => {
    const { afostoAccessToken: accessToken } = this.state;
    const { onAccessTokenSave } = this.props;

    if (accessToken && accessToken !== '') {
      onAccessTokenSave(accessToken);
    }
  };

  handleSelectPrinter = selectedID => () => {
    const { onSelectPrinter, printers } = this.props;
    const selectedPrinter = printers.find(printer => printer.id === selectedID);

    onSelectPrinter({
      selectedPrinterID: selectedPrinter.id,
      selectedHubID: selectedPrinter.hub_id,
    });
    this.handleCloseOption();
  };

  handleOpenOption = option => () => {
    this.setState({
      openSetting: option,
    });
  };

  handleCloseOption = () => {
    this.setState({
      openSetting: null,
    });
  };

  toggleAccessTokenModal = accessTokenModalIsOpen => () => {
    const { accessToken, onAccessTokenValidate, onToggleAccessTokenModal } = this.props;
    onToggleAccessTokenModal(accessTokenModalIsOpen);

    if (!accessTokenModalIsOpen) {
      this.setState({
        afostoAccessToken: accessToken,
      });

      onAccessTokenValidate(accessToken);
    }
  };

  render() {
    const {
      intl,
      history,
      printers,
      selectedPrinterID,
      accessToken,
      accessTokenIsValid,
      accessTokenValidationError,
      accessTokenModalIsOpen,
      isGettingPrinters,
    } = this.props;
    const { afostoAccessToken, openSetting } = this.state;
    const isAccessTokenEmpty = !afostoAccessToken || afostoAccessToken === '';
    const accessTokenErrorMessage =
      accessTokenValidationError && accessTokenValidationError.toLowerCase() === 'network error'
        ? 'Invalid token'
        : null;

    return (
      <>
        <AppBar position="sticky" color="default" elevation={1}>
          <Toolbar>
            <IconButton onClick={() => history.push('/')}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              <FormattedMessage {...translations.title} />
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button onClick={this.toggleAccessTokenModal(true)}>
            <ListItemText
              primary={intl.formatMessage(translations.settings.afostoAccessToken)}
              secondary={accessToken || intl.formatMessage(translations.errors.noAccessToken)}
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={this.handleOpenOption('spoolerPrinter')}
            disabled={!printers.length}
          >
            <ListItemText
              primary={intl.formatMessage(translations.settings.spoolerPrinter)}
              secondary={
                printers.find(printer => printer.id === selectedPrinterID)
                  ? printers.find(printer => printer.id === selectedPrinterID).name
                  : '-'
              }
            />
            {isGettingPrinters ? (
              <ListItemIcon>
                <CircularProgress size={20} />
              </ListItemIcon>
            ) : null}
          </ListItem>
        </List>
        <Dialog
          open={accessTokenModalIsOpen}
          onClose={this.toggleAccessTokenModal(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            <FormattedMessage {...translations.settings.afostoAccessToken} />
          </DialogTitle>
          <DialogContent>
            <TextField
              id="afosto-access-token"
              label="Afosto access token"
              value={afostoAccessToken || accessToken}
              onChange={this.handleChangeAccessToken}
              margin="normal"
              variant="outlined"
              autoComplete="off"
              fullWidth
              autoFocus
              error={!accessTokenIsValid && !isAccessTokenEmpty}
            />
            <Typography variant="body2" color="error">
              {!accessTokenIsValid ? accessTokenErrorMessage : ''}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button size="medium" color="primary" onClick={this.toggleAccessTokenModal(false)}>
              <FormattedMessage {...translations.actions.cancel} />
            </Button>
            <Button
              size="medium"
              color="primary"
              onClick={this.handleSaveAccessToken}
              disabled={!accessTokenIsValid}
            >
              <FormattedMessage {...translations.actions.save} />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSetting === 'spoolerPrinter'}
          onClose={this.handleCloseOption}
          fullWidth
          maxWidth="xs"
        >
          <List>
            {printers.map(printer => (
              <ListItem key={printer.id} button onClick={this.handleSelectPrinter(printer.id)}>
                <Radio checked={selectedPrinterID === printer.id} tabIndex={-1} />
                <ListItemText primary={printer.name} secondary={printer.connection.interface} />
              </ListItem>
            ))}
          </List>
        </Dialog>
      </>
    );
  }
}

SettingsPage.defaultProps = {
  history: undefined,
  printers: [],
  selectedPrinterID: null,
  onAccessTokenValidate: undefined,
  onAccessTokenSave: undefined,
  accessTokenValidationError: false,
  onToggleAccessTokenModal: undefined,
  accessToken: '',
  accessTokenIsValid: false,
  accessTokenModalIsOpen: false,
  onSelectPrinter: undefined,
  isGettingPrinters: false,
  intl: {},
};

SettingsPage.propTypes = {
  history: PropTypes.object,
  printers: PropTypes.array,
  selectedPrinterID: PropTypes.string,
  onAccessTokenValidate: PropTypes.func,
  accessTokenValidationError: PropTypes.bool,
  onAccessTokenSave: PropTypes.func,
  onToggleAccessTokenModal: PropTypes.func,
  accessToken: PropTypes.string,
  accessTokenIsValid: PropTypes.bool,
  accessTokenModalIsOpen: PropTypes.bool,
  onSelectPrinter: PropTypes.func,
  isGettingPrinters: PropTypes.bool,
  intl: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  onAccessTokenValidate: accessToken => dispatch(validateAccessToken(accessToken)),
  onAccessTokenSave: accessToken => dispatch(saveAccessToken(accessToken)),
  onToggleAccessTokenModal: accessTokenModalIsOpen =>
    dispatch(setShowAccessTokenModal(accessTokenModalIsOpen)),
  onSelectPrinter: payload => dispatch(setSelectedPrinter(payload)),
});

const mapStateToProps = state => ({
  accessToken: state.authorization.accessToken,
  accessTokenIsValid: state.authorization.accessTokenIsValid,
  accessTokenModalIsOpen: state.authorization.accessTokenModalIsOpen,
  accessTokenValidationError: state.authorization.accessTokenValidationError,
  printers: state.spooler.printers,
  selectedPrinterID: state.spooler.selectedPrinterID,
  isGettingPrinters: state.spooler.isGettingPrinters,
});

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(SettingsPage);

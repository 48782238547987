import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import historyObject from './history';
import rootSaga from './sagas';
import rootReducer from './reducers';
import LogRocket from 'logrocket';

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middleware = [routerMiddleware(historyObject), sagaMiddleware, LogRocket.reduxMiddleware()];

const store = createStore(
  rootReducer(historyObject),
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

sagaMiddleware.run(rootSaga);

export default store;

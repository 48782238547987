import {
  SPOOLER_SET_PRINTERS,
  SPOOLER_SET_SELECTED_PRINTER,
  SPOOLER_IS_GETTING_PRINTERS,
  SPOOLER_GET_PRINTERS_FAILURE,
  SPOOLER_SET_SELECTED_PRINTER_FAILURE,
  SPOOLER_SET_ACTIVE_JOBS,
  SPOOLER_UPDATE_PRINT_JOB_STATUS_FAILURE,
} from './types';

export const initialState = {
  isGettingPrinters: false,
  getPrintersError: null,
  printers: [],
  selectedPrinterID: null,
  selectedHubID: null,
  setSelectedPrinterError: null,
  updatePrintJobStatusError: null,
  activeJobs: [],
};

const spoolerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPOOLER_SET_PRINTERS:
      return {
        ...state,
        printers: action.printers,
      };
    case SPOOLER_SET_SELECTED_PRINTER:
      return {
        ...state,
        selectedPrinterID: action.payload.selectedPrinterID,
        selectedHubID: action.payload.selectedHubID,
      };
    case SPOOLER_SET_SELECTED_PRINTER_FAILURE:
      return {
        ...state,
        setSelectedPrinterError: action.error,
      };
    case SPOOLER_SET_ACTIVE_JOBS:
      return {
        ...state,
        activeJobs: [...action.activeJobs],
      };
    case SPOOLER_IS_GETTING_PRINTERS:
      return {
        ...state,
        isGettingPrinters: action.isGettingPrinters,
      };
    case SPOOLER_GET_PRINTERS_FAILURE:
      return {
        ...state,
        getPrintersError: action.error,
      };
    case SPOOLER_UPDATE_PRINT_JOB_STATUS_FAILURE:
      return {
        ...state,
        updatePrintJobStatusError: action.error,
      };
    default:
      return state;
  }
};

export { spoolerReducer };

export default spoolerReducer;

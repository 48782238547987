import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import historyObject from './history';
import Root from './pages/Root/Root';
import Settings from './pages/Settings/Settings';

const Router = () => (
  <ConnectedRouter history={historyObject}>
    <Switch>
      <Route path="/settings" component={Settings} />
      <Route path="/" component={Root} />
    </Switch>
  </ConnectedRouter>
);

export default Router;

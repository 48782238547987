const translations = {
  app: {
    callback: {
      redirecting: 'Doorverwijzen',
    },
    navigation: {
      menu: {
        buttonLabel: 'Menu',
        items: {
          settings: 'Instellingen',
        },
      },
      filter: {
        buttonLabel: 'Filters',
      },
      search: {
        buttonLabel: 'Camera',
        placeholder: 'Zoek een verzending',
      },
    },
    settings: {
      automaticPrinting: {
        title: 'Pakket label printen',
        description: 'Bij één resultaat',
      },
    },
    shipment: {
      status: {
        sent: 'Verzonden',
        partialsent: 'Gedeeltelijk verzonden',
        notsent: 'Niet verzonden',
        pendingcustomer: 'Wachten op klant',
      },
    },
    messages: {
      noSearchTerm: 'Vul een pakbon nummer in',
      noResultsForSearchTerm: 'Geen resultaten gevonden',
      pressEnterToSearch: 'Druk op enter om te zoeken',
      noAccessToken: 'U heeft nog geen access token ingevuld',
      accessTokenInvalid: 'Uw access token is invalide',
      noPrinterSelected: 'U heeft nog geen printer geselecteerd',
      openSettingsButton: 'Open instellingen',
      updateAvailableMessage: 'Nieuwe app versie beschikbaar',
      updateButtonAction: 'Bijwerken',
    },
  },
  settings: {
    title: 'Instellingen',
    settings: {
      afostoAccessToken: 'Afosto access token',
      spoolerPrinter: 'Spooler printer',
    },
    actions: {
      save: 'Opslaan',
      cancel: 'Annuleren',
    },
    errors: {
      noAccessToken: 'Geen access token',
    },
  },
  confirmCancelFormDialog: {
    title: 'Venster verlaten',
    description:
      'Weet je zeker dat je het venster wilt verlaten? Wijzigingen zullen niet worden opgeslagen.',
    buttons: {
      cancel: 'Annuleren',
      leaveWindow: 'Venster verlaten',
    },
  },
};

export default translations;

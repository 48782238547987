import { all, call, fork, put, take } from 'redux-saga/effects';
import { APP_INITIALIZE } from './types';
import { setInitializationFailure, setIsInitializing, updateQueuedTask } from './actions';
import { initializeAccessToken } from '../authorization/actions';
import { initPrinterSelection } from '../spooler/actions';
import {
  SPOOLER_PRINT_JOB_ERROR,
  SPOOLER_PRINT_JOB_UPDATE,
  SPOOLER_START_PRINT_JOB,
} from '../spooler/types';
import { addTaskLog } from './functions';
import { TASK_LOG_TYPE_ERROR, TASK_STATUS_ERROR, TASK_STATUS_SUCCESS } from './constants';

export function* handleInitialization() {
  try {
    yield put(setIsInitializing(true));
    yield put(initializeAccessToken());
    yield put(initPrinterSelection());
    yield put(setIsInitializing(false));
  } catch (err) {
    yield put(setInitializationFailure(err.message));
    yield put(setIsInitializing(false));
  }
}

export function* handleStartPrintJob({ job }) {
  const { key, ...jobData } = job;
  let task = {
    id: key,
    payload: { job: jobData },
  };

  try {
    yield put(updateQueuedTask(task));
  } catch (err) {
    task = yield call(addTaskLog, task, {
      type: TASK_LOG_TYPE_ERROR,
      message: `An error occurred: ${err.message}`,
    });
    yield put(updateQueuedTask(task));
  }
}

export function* handlePrintJobError({ job }) {
  const { key, errors } = job;
  const logs = errors.map(errorMessage => ({
    type: TASK_LOG_TYPE_ERROR,
    message: errorMessage,
  }));
  let task = { id: key, logs };

  try {
    yield put(updateQueuedTask(task));
  } catch (err) {
    task = yield call(addTaskLog, task, {
      type: TASK_LOG_TYPE_ERROR,
      message: `An error occurred: ${err.message}`,
    });
    yield put(updateQueuedTask(task));
  }
}

export function* handlePrintJobUpdate({ job }) {
  const { key, errors, ...jobData } = job;
  const logs = errors.map(errorMessage => ({
    type: TASK_LOG_TYPE_ERROR,
    message: errorMessage,
  }));
  let task = { id: key, logs, payload: { job: jobData } };

  try {
    if (job.status === 'success') {
      task.status = TASK_STATUS_SUCCESS;
    } else if (job.status !== 'pending') {
      task.status = TASK_STATUS_ERROR;
    }

    yield put(updateQueuedTask(task));
  } catch (err) {
    task = yield call(addTaskLog, task, {
      type: TASK_LOG_TYPE_ERROR,
      message: `An error occurred: ${err.message}`,
    });
    yield put(updateQueuedTask(task));
  }
}

export function* initializationFlow() {
  while (true) {
    yield take(APP_INITIALIZE);
    yield call(handleInitialization);
  }
}

export function* printJobStartFlow() {
  while (true) {
    const data = yield take(SPOOLER_START_PRINT_JOB);
    yield fork(handleStartPrintJob, data);
  }
}

export function* printJobErrorFlow() {
  while (true) {
    const data = yield take(SPOOLER_PRINT_JOB_ERROR);
    yield fork(handlePrintJobError, data);
  }
}

export function* printJobUpdateFlow() {
  while (true) {
    const data = yield take(SPOOLER_PRINT_JOB_UPDATE);
    yield fork(handlePrintJobUpdate, data);
  }
}

export function* appSaga() {
  const sagas = [initializationFlow, printJobStartFlow, printJobErrorFlow, printJobUpdateFlow];
  yield all(sagas.map(saga => fork(saga)));
}

export default appSaga;

import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';

const NavigationBarDivider = styled(Divider)`
  && {
    width: 1px;
    height: 28px;
    margin: 4px;
  }
`;

export default NavigationBarDivider;

import styled, { keyframes } from 'styled-components';

const flash = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

const ScannerContainer = styled('div')`
  position: relative;

  & video {
    width: 100%;
    height: 100%;
    background-color: #999999;
  }

  & canvas {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
  }

  &.detected-barcode::after {
    animation: ${flash} ease-out 0.3s;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    max-width: 250px;
    height: 2px;
    transform: translate(-50%, -50%);
    background-color: ${props => props.theme.palette.secondary.main};
    border-radius: 1px;
    mix-blend-mode: hard-light;
  }

  &.scanner-safari-fix {
    height: 200px;
    overflow: hidden;

    & video {
      height: initial;

      &[muted='true'] {
        margin-top: -50%;
      }
    }
  }
`;

export default ScannerContainer;

import { v4 as uuid } from 'uuid';
import { TASK_LOG_TYPE_ERROR, TASK_STATUS_ERROR, TASK_STATUS_PENDING } from './constants';

export const createTask = type => ({
  id: uuid(),
  type,
  logs: [],
  context: [],
  status: TASK_STATUS_PENDING,
  payload: {},
});

export const addTaskLog = (task, { type, message }) => {
  const updatedTask = {
    ...task,
    logs: [...task.logs, { type, message }],
  };

  if (type === TASK_LOG_TYPE_ERROR) {
    updatedTask.status = TASK_STATUS_ERROR;
  }

  return updatedTask;
};

import MyWorker from './workers/poll.worker';
import { pollJobStatus } from './functions';
import store from '../../store';
import { updatePrintJobStatus } from './actions';

const pollWorker = new MyWorker();
let activeJobs = [];

export const findActiveJob = reference => activeJobs.find(active => active.reference === reference);

export const removeActiveJob = reference =>
  activeJobs.filter(active => active.reference !== reference);

export const addJobToPolling = job => {
  const { reference } = job;
  const activeJob = findActiveJob(reference);

  if (activeJob) {
    activeJobs = removeActiveJob(reference);
  }

  activeJobs.push(job);

  const startTime = new Date().getTime();
  const interval = setInterval(() => {
    if (new Date().getTime() - startTime >= 60000) {
      clearInterval(interval);
      activeJobs = removeActiveJob(reference);
      return;
    }

    if (!findActiveJob(reference)) {
      clearInterval(interval);
      return;
    }

    pollWorker.postMessage({
      type: 'pollJobStatus',
      payload: job,
    });
  }, 2000);
};

export const handleJobPolling = job => {
  const { reference } = job;

  return pollJobStatus(reference).then(({ status }) => {
    if (status && status !== 'pending') {
      activeJobs = removeActiveJob(reference);
      store.dispatch(updatePrintJobStatus({ ...job, reference, status }));
    }
  });
};

pollWorker.addEventListener('message', messageEvent => {
  switch (messageEvent.data.type) {
    case 'addActiveJob':
      addJobToPolling(messageEvent.data.payload);
      break;
    case 'pollJobStatus':
      handleJobPolling(messageEvent.data.payload);
      break;
    default:
      break;
  }
});

export default pollWorker;

import {
  APP_SET_IS_INITIALIZING,
  APP_INITIALIZE_FAILURE,
  APP_SET_SERVICE_WORKER_UPDATE_AVAILABLE,
  APP_UPDATE_QUEUED_TASK,
  APP_ENQUEUE_TASK,
} from './types';

export const initialState = {
  isInitializing: false,
  initializationError: null,
  serviceWorkerUpdateAvailable: false,
  taskQueue: [],
};

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case APP_SET_IS_INITIALIZING:
      return {
        ...state,
        isInitializing: action.isInitializing,
      };
    case APP_INITIALIZE_FAILURE:
      return {
        ...state,
        initializationError: action.error,
      };
    case APP_SET_SERVICE_WORKER_UPDATE_AVAILABLE:
      return {
        ...state,
        serviceWorkerUpdateAvailable: action.serviceWorkerUpdateAvailable,
      };
    case APP_ENQUEUE_TASK:
      return {
        ...state,
        taskQueue: [...state.taskQueue, action.task],
      };
    case APP_UPDATE_QUEUED_TASK:
      return {
        ...state,
        taskQueue: state.taskQueue.reduce((acc, currentTask) => {
          if (currentTask.id === action.task.id) {
            acc.push({ ...currentTask, ...action.task });
          } else {
            acc.push(currentTask);
          }

          return acc;
        }, []),
      };
    default:
      return state;
  }
}

export default appReducer;

const translations = {
  app: {
    callback: {
      redirecting: 'Redirecting',
    },
    navigation: {
      menu: {
        buttonLabel: 'Menu',
        items: {
          settings: 'Settings',
        },
      },
      filter: {
        buttonLabel: 'Filters',
      },
      search: {
        buttonLabel: 'Camera',
        placeholder: 'Search a shipment',
      },
    },
    settings: {
      automaticPrinting: {
        title: 'Print package label',
        description: 'When only one result',
      },
    },
    shipment: {
      status: {
        sent: 'Sent',
        partialsent: 'Partially sent',
        notsent: 'Not sent',
        pendingcustomer: 'Waiting for customer',
      },
    },
    messages: {
      noSearchTerm: 'Enter a packing slip number',
      noResultsForSearchTerm: 'No results found',
      pressEnterToSearch: 'Press enter to search',
      noAccessToken: 'No access token found',
      accessTokenInvalid: 'Your access token is invalid',
      noPrinterSelected: 'No printer selected',
      openSettingsButton: 'Open settings',
      updateAvailableMessage: 'New app version available',
      updateButtonAction: 'Update',
    },
  },
  settings: {
    title: 'Settings',
    settings: {
      afostoAccessToken: 'Afosto access token',
      spoolerPrinter: 'Spooler printer',
    },
    actions: {
      save: 'Save',
      cancel: 'Cancel',
    },
    errors: {
      noAccessToken: 'No access token',
    },
  },
};

export default translations;

import { defineMessages } from 'react-intl';

const translations = {
  title: {
    id: 'settings.title',
    defaultMessage: 'Settings',
    description: 'Settings page title',
  },
  settings: {
    afostoAccessToken: {
      id: 'settings.settings.afostoAccessToken',
      defaultMessage: 'Afosto access token',
      description: 'Afosto access token field label',
    },
    spoolerPrinter: {
      id: 'settings.settings.spoolerPrinter',
      defaultMessage: 'Spooler printer',
      description: 'Spooler printer field label',
    },
  },
  actions: {
    save: {
      id: 'settings.actions.save',
      defaultMessage: 'Save',
      description: 'Save button label',
    },
    cancel: {
      id: 'settings.actions.cancel',
      defaultMessage: 'Cancel',
      description: 'Cancel button label',
    },
  },
  errors: {
    noAccessToken: {
      id: 'settings.errors.noAccessToken',
      defaultMessage: 'No access token',
      description: 'message to tell there is no access token saved',
    },
  },
};

export default defineMessages(translations);

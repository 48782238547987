import apiService from '../../utils/ApiService';
import pollWorker from './workers';

const { REACT_APP_SPOOLER_API_URL: SPOOLER_API_URL } = process.env;

export function getPrintersRequest() {
  return apiService
    .get(`${SPOOLER_API_URL}/spl/printers`, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${localStorage.getItem('spoolerAccessToken')}`,
      },
    })
    .then(({ data }) => data);
}

export function setSelectedPrinterRequest({ selectedPrinterID, selectedHubID }) {
  localStorage.setItem('selectedPrinterID', selectedPrinterID);
  localStorage.setItem('selectedHubID', selectedHubID);
}

export function createAttachmentJob(attachments = [], devices = []) {
  if (attachments.length && devices.length) {
    return [
      {
        kind: 'print',
        api: 'v1',
        document: {
          devices,
          attachments,
        },
      },
    ];
  }

  return null;
}

export function printJob(hubId, job, description, tag) {
  return apiService
    .post(
      `${SPOOLER_API_URL}/spl/queue`,
      {
        hub_id: hubId,
        description,
        tag,
        job,
      },
      {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Bearer ${localStorage.getItem('spoolerAccessToken')}`,
        },
      },
    )
    .then(({ data }) => data);
}

export function getSelectedPrinterID() {
  const selectedPrinterID = localStorage.getItem('selectedPrinterID');
  return !selectedPrinterID || selectedPrinterID === 'null' ? null : selectedPrinterID;
}

export function getSelectedHubID() {
  const selectedHubID = localStorage.getItem('selectedHubID');
  return !selectedHubID || selectedHubID === 'null' ? null : selectedHubID;
}

export function setSpoolerAccessToken(accessToken) {
  localStorage.setItem('spoolerAccessToken', accessToken);
}

export function addActiveJobs(jobs) {
  jobs.forEach(job => {
    pollWorker.postMessage({
      type: 'addActiveJob',
      payload: { ...job },
    });
  });
}

export function pollJobStatus(reference) {
  return apiService
    .get(`${SPOOLER_API_URL}/spl/queue/${reference}`, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${localStorage.getItem('spoolerAccessToken')}`,
      },
    })
    .then(({ data }) => data);
}

import React from 'react';
import PropTypes from 'prop-types';
import { StatusDisplay } from '../styles';

const ShipmentStatus = ({ status, children }) => {
  let displayStatus = 'error';

  switch (status) {
    case 'sent':
      displayStatus = 'success';
      break;
    case 'pendingcustomer':
      displayStatus = 'warning';
      break;
    case 'notsent':
      displayStatus = 'error';
      break;
    default:
      displayStatus = 'error';
  }
  return (
    <StatusDisplay component="span" variant="button" status={displayStatus}>
      {children}
    </StatusDisplay>
  );
};

ShipmentStatus.propTypes = {
  status: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ShipmentStatus;

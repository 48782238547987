import { fork } from 'redux-saga/effects';
import { appSaga } from './modules/app/sagas';
import { shipmentsSaga } from './modules/shipments/sagas';
import { authorizationSaga } from './modules/authorization/sagas';
import { spoolerSaga } from './modules/spooler/sagas';

export default function* rootSaga() {
  yield fork(appSaga);
  yield fork(shipmentsSaga);
  yield fork(authorizationSaga);
  yield fork(spoolerSaga);
}

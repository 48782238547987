export const SPOOLER_GET_PRINTERS = 'SPOOLER_GET_PRINTERS';
export const SPOOLER_GET_PRINTERS_FAILURE = 'SPOOLER_GET_PRINTERS_FAILURE';
export const SPOOLER_IS_GETTING_PRINTERS = 'SPOOLER_IS_GETTING_PRINTERS';
export const SPOOLER_SET_PRINTERS = 'SPOOLER_SET_PRINTERS';
export const SPOOLER_SET_ACTIVE_JOBS = 'SPOOLER_SET_ACTIVE_JOBS';
export const SPOOLER_SET_SELECTED_PRINTER = 'SPOOLER_SET_SELECTED_PRINTER';
export const SPOOLER_SET_SELECTED_PRINTER_FAILURE = 'SPOOLER_SET_SELECTED_PRINTER_FAILURE';
export const SPOOLER_PRINT_ATTACHMENT_JOB = 'SPOOLER_PRINT_ATTACHMENT_JOB';
export const SPOOLER_INIT_PRINTER_SELECTION = 'SPOOLER_INIT_PRINTER_SELECTION';
export const SPOOLER_UPDATE_PRINT_JOB_STATUS = 'SPOOLER_UPDATE_PRINT_JOB_STATUS';
export const SPOOLER_UPDATE_PRINT_JOB_STATUS_FAILURE = 'SPOOLER_UPDATE_PRINT_JOB_STATUS_FAILURE';
export const SPOOLER_START_PRINT_JOB = 'SPOOLER_START_PRINT_JOB';
export const SPOOLER_PRINT_JOB_ERROR = 'SPOOLER_PRINT_JOB_ERROR';
export const SPOOLER_PRINT_JOB_UPDATE = 'SPOOLER_PRINT_JOB_UPDATE';

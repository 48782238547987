export const AUTH_VALIDATE_ACCESS_TOKEN = 'AUTH_VALIDATE_ACCESS_TOKEN';
export const AUTH_IS_VALIDATING_ACCESS_TOKEN = 'AUTH_IS_VALIDATING_ACCESS_TOKEN';
export const AUTH_SET_ACCESS_TOKEN_IS_VALID = 'AUTH_SET_ACCESS_TOKEN_IS_VALID';
export const AUTH_ACCESS_TOKEN_VALIDATION_FAILURE = 'AUTH_ACCESS_TOKEN_VALIDATION_FAILURE';
export const AUTH_INIT_ACCESS_TOKEN = 'AUTH_INIT_ACCESS_TOKEN';
export const AUTH_SAVE_ACCESS_TOKEN = 'AUTH_SAVE_ACCESS_TOKEN';
export const AUTH_SET_IS_SAVING_ACCESS_TOKEN = 'AUTH_SET_IS_SAVING_ACCESS_TOKEN';
export const AUTH_ACCESS_TOKEN_SAVE_FAILURE = 'AUTH_ACCESS_TOKEN_SAVE_FAILURE';
export const AUTH_SET_SHOW_ACCESS_TOKEN_MODAL = 'AUTH_SET_SHOW_ACCESS_TOKEN_MODAL';
export const AUTH_SET_ACCESS_TOKEN = 'AUTH_SET_ACCESS_TOKEN';
export const AUTH_CLEAR_ACCESS_TOKEN = 'AUTH_CLEAR_ACCESS_TOKEN';

import nl from './nl';
import en from './en';

export { nl, en };

const translations = {
  nl,
  en,
};

export default translations;

import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

const NavigationBarButton = styled(IconButton)`
  && {
    padding: 10px;
  }
`;

export default NavigationBarButton;

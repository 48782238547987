import {
  SPOOLER_GET_PRINTERS,
  SPOOLER_GET_PRINTERS_FAILURE,
  SPOOLER_INIT_PRINTER_SELECTION,
  SPOOLER_IS_GETTING_PRINTERS,
  SPOOLER_PRINT_ATTACHMENT_JOB,
  SPOOLER_SET_PRINTERS,
  SPOOLER_SET_SELECTED_PRINTER,
  SPOOLER_SET_SELECTED_PRINTER_FAILURE,
  SPOOLER_SET_ACTIVE_JOBS,
  SPOOLER_UPDATE_PRINT_JOB_STATUS,
  SPOOLER_UPDATE_PRINT_JOB_STATUS_FAILURE,
  SPOOLER_START_PRINT_JOB,
  SPOOLER_PRINT_JOB_ERROR,
  SPOOLER_PRINT_JOB_UPDATE,
} from './types';

export const getPrinters = () => ({
  type: SPOOLER_GET_PRINTERS,
});

export const setSelectedPrinter = payload => ({
  type: SPOOLER_SET_SELECTED_PRINTER,
  payload,
});

export const initPrinterSelection = () => ({
  type: SPOOLER_INIT_PRINTER_SELECTION,
});

export const printAttachments = payload => ({
  type: SPOOLER_PRINT_ATTACHMENT_JOB,
  payload,
});

export const setIsGettingPrinters = isGettingPrinters => ({
  type: SPOOLER_IS_GETTING_PRINTERS,
  isGettingPrinters,
});

export const setGetPrintersFailure = error => ({
  type: SPOOLER_GET_PRINTERS_FAILURE,
  error,
});

export const setPrinters = printers => ({
  type: SPOOLER_SET_PRINTERS,
  printers,
});

export const setSelectedPrinterFailure = error => ({
  type: SPOOLER_SET_SELECTED_PRINTER_FAILURE,
  error,
});

export const setActiveJobs = activeJobs => ({
  type: SPOOLER_SET_ACTIVE_JOBS,
  activeJobs,
});

export const updatePrintJobStatus = job => ({
  type: SPOOLER_UPDATE_PRINT_JOB_STATUS,
  job,
});

export const setUpdatePrintJobStatusFailure = error => ({
  type: SPOOLER_UPDATE_PRINT_JOB_STATUS_FAILURE,
  error,
});

export const startPrintJob = job => ({
  type: SPOOLER_START_PRINT_JOB,
  job,
});

export const setPrintJobError = job => ({
  type: SPOOLER_PRINT_JOB_ERROR,
  job,
});

export const updatePrintJob = job => ({
  type: SPOOLER_PRINT_JOB_UPDATE,
  job,
});

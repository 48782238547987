import React from 'react';
import PropTypes from 'prop-types';
import translations from '../translations';
import MenuIcon from '@material-ui/icons/Menu';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import TuneIcon from '@material-ui/icons/Tune';
import Hidden from '@material-ui/core/Hidden';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import NavigationBarButton from '../../../components/NavigationBar/NavigationBarButton/NavigationBarButton';
import NavigationBarSearch from '../../../components/NavigationBar/NavigationBarSearch/NavigationBarSearch';
import NavigationBarDivider from '../../../components/NavigationBar/NavigationBarDivider/NavigationBarDivider';
import BarcodeScanner from '../../../components/BarcodeScanner/BarcodeScanner';
import { AppHeader, NavigationBarGroup, NavigationBarInnerContainer } from './styles';
import { injectIntl } from 'react-intl';
import { cameraIsSupported, isMobileDevice } from '../../../utils/device';

const MainNavigationBar = ({
  intl,
  toggleDrawer,
  searchTerm,
  handleInput,
  handleKeyUp,
  searchBarRef,
  toggleCamera,
  cameraIsOpen,
  onDetected,
}) => (
  <AppHeader color="default" position="sticky" elevation={0}>
    <NavigationBar>
      <NavigationBarInnerContainer>
        <NavigationBarGroup>
          <NavigationBarButton
            aria-label={intl.formatMessage(translations.navigation.menu.buttonLabel)}
            title={intl.formatMessage(translations.navigation.menu.buttonLabel)}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </NavigationBarButton>
          <NavigationBarSearch
            placeholder={intl.formatMessage(translations.navigation.search.placeholder)}
            value={searchTerm}
            inputRef={searchBarRef}
            onInput={handleInput}
            onKeyUp={handleKeyUp}
            inputProps={{
              'aria-label': intl.formatMessage(translations.navigation.search.placeholder),
            }}
            autoFocus
          />
          {isMobileDevice() && cameraIsSupported() ? (
            <NavigationBarButton
              aria-label={intl.formatMessage(translations.navigation.search.buttonLabel)}
              title={intl.formatMessage(translations.navigation.search.buttonLabel)}
              onClick={toggleCamera}
            >
              {cameraIsOpen ? <CenterFocusWeakIcon /> : <CenterFocusStrongIcon />}
            </NavigationBarButton>
          ) : null}
          <Hidden mdUp>
            <NavigationBarDivider />
            <NavigationBarButton
              aria-label={intl.formatMessage(translations.navigation.filter.buttonLabel)}
              title={intl.formatMessage(translations.navigation.filter.buttonLabel)}
              disabled
            >
              <TuneIcon />
            </NavigationBarButton>
          </Hidden>
        </NavigationBarGroup>
        {cameraIsOpen ? <BarcodeScanner onDetected={onDetected} /> : null}
      </NavigationBarInnerContainer>
    </NavigationBar>
  </AppHeader>
);

MainNavigationBar.defaultProps = {
  intl: undefined,
  toggleDrawer: undefined,
  searchTerm: '',
  handleInput: undefined,
  handleKeyUp: undefined,
  searchBarRef: undefined,
  toggleCamera: undefined,
  cameraIsOpen: false,
  onDetected: undefined,
};

MainNavigationBar.propTypes = {
  intl: PropTypes.object,
  toggleDrawer: PropTypes.func,
  searchTerm: PropTypes.string,
  handleInput: PropTypes.func,
  handleKeyUp: PropTypes.func,
  searchBarRef: PropTypes.object,
  toggleCamera: PropTypes.func,
  cameraIsOpen: PropTypes.bool,
  onDetected: PropTypes.func,
};

export default injectIntl(MainNavigationBar);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Base, ResultsContainer, MessageContainer, SuccessCheckIcon } from './styles';
import translations from './translations';
import { downloadShipmentDocuments, searchShipments } from '../../modules/shipments/actions';
import Item from '../../components/Item/Item';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Drawer from './components/NavigationDrawer';
import BottomAppBar from './components/BottomAppBar';
import MainNavigationBar from './components/MainNavigationBar';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import PrintIcon from '@material-ui/icons/Print';
import { push } from 'connected-react-router';

export class RootPage extends Component {
  searchBarRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      drawerIsOpen: false,
      printSingleResult: localStorage.getItem('print_single_result') === 'true',
      cameraIsOpen: false,
      searchTerm: '',
    };
  }

  navigateTo = path => () => {
    const { pushHistory } = this.props;
    pushHistory(path);
  };

  getMenuItems = () => {
    const { intl } = this.props;

    return [
      {
        name: 'settings',
        label: intl.formatMessage(translations.navigation.menu.items.settings),
        onClick: this.navigateTo('/settings'),
      },
    ];
  };

  handleInput = event => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm });
  };

  handleKeyUp = event => {
    const key = (event.key || '').toLowerCase();

    if (key === 'enter') {
      const searchTerm = event.target.value;

      this.handleSearch({
        searchTerm,
      });

      this.setState({
        searchTerm: '',
      });
    }
  };

  handleSearch = ({ searchTerm }) => {
    const { printSingleResult } = this.state;
    const { onSearch } = this.props;

    this.setState({
      searchTerm,
    });

    onSearch(
      {
        q: searchTerm,
        page_size: 20,
        page: 1,
      },
      printSingleResult,
    );
  };

  handlePrint = shipment => () => {
    const { onPrint } = this.props;

    onPrint([
      {
        shipment_id: shipment.id,
        type: 'label',
      },
    ]);
  };

  handleSingleResultSetting = () => {
    const { printSingleResult } = this.state;
    this.setState({
      printSingleResult: !printSingleResult,
    });
    localStorage.setItem('print_single_result', !printSingleResult);
  };

  onDetected = ({ codeResult }) => {
    this.setState({ searchTerm: codeResult.code });
    const event = new KeyboardEvent('keyup', { key: 'Enter', bubbles: true });
    this.searchBarRef.current.dispatchEvent(event);
  };

  toggleCamera = () => {
    const { cameraIsOpen } = this.state;
    this.setState({ cameraIsOpen: !cameraIsOpen });
  };

  toggleDrawer = open => () => {
    this.setState({
      drawerIsOpen: open,
    });
  };

  getErrorMessage = () => {
    const {
      accessTokenIsValid,
      accessToken,
      results,
      selectedPrinterID,
      intl,
      taskQueue,
    } = this.props;
    const { searchTerm } = this.state;
    const [lastSearchQueryStatus] = taskQueue.filter(task => task.type === 'search').slice(-1);

    if (!accessTokenIsValid) {
      if (accessToken === undefined) {
        return {
          message: intl.formatMessage(translations.messages.noAccessToken),
          path: '/settings',
          label: intl.formatMessage(translations.messages.openSettingsButton),
        };
      }

      return {
        message: intl.formatMessage(translations.messages.accessTokenInvalid),
        path: '/settings',
        label: intl.formatMessage(translations.messages.openSettingsButton),
      };
    }
    if (selectedPrinterID === null) {
      return {
        message: intl.formatMessage(translations.messages.noPrinterSelected),
        path: '/settings',
        label: intl.formatMessage(translations.messages.openSettingsButton),
      };
    }
    if (searchTerm !== '' && results < 1 && !lastSearchQueryStatus) {
      return {
        message: intl.formatMessage(translations.messages.pressEnterToSearch),
      };
    }
    if (
      searchTerm !== '' &&
      results < 1 &&
      lastSearchQueryStatus &&
      lastSearchQueryStatus.status !== 'pending'
    ) {
      return {
        message: intl.formatMessage(translations.messages.pressEnterToSearch),
      };
    }
    if (
      searchTerm === '' &&
      results < 1 &&
      lastSearchQueryStatus &&
      lastSearchQueryStatus.status === 'error'
    ) {
      return {
        message: intl.formatMessage(translations.messages.noResultsForSearchTerm),
      };
    }
    if (searchTerm === '' && results < 1) {
      return {
        message: intl.formatMessage(translations.messages.noSearchTerm),
      };
    }

    return null;
  };

  getLastTaskForShipment = shipmentId => {
    const { taskQueue } = this.props;
    const [lastTask] = taskQueue
      .filter(task => task.context.find(item => item.key === shipmentId))
      .slice(-1);

    return lastTask;
  };

  getCorrectIcon = (status, type) => {
    let icon;

    if (type === 'download' && status === 'pending') {
      icon = <VerticalAlignBottomIcon />;
    } else if (type === 'print' && status === 'success') {
      icon = <SuccessCheckIcon />;
    } else {
      icon = <PrintIcon />;
    }

    return icon;
  };

  render() {
    const { results, history, taskQueue } = this.props;
    const { printSingleResult, cameraIsOpen, searchTerm, drawerIsOpen } = this.state;
    const [lastSearchQueryStatus] = taskQueue.filter(task => task.type === 'search').slice(-1);
    const errorMessage = this.getErrorMessage(this.props);

    const Body = () => {
      if (lastSearchQueryStatus && lastSearchQueryStatus.status === 'pending') {
        return (
          <MessageContainer>
            <CircularProgress />
          </MessageContainer>
        );
      }

      if (errorMessage) {
        const messageButton = (
          <Button color="primary" onClick={() => history.push(errorMessage.path)}>
            {errorMessage.label}
          </Button>
        );
        return (
          <MessageContainer>
            <Typography color="textSecondary" variant="h6" align="center">
              {errorMessage.message}
            </Typography>
            {errorMessage.path && errorMessage.label ? messageButton : null}
          </MessageContainer>
        );
      }
      return (
        <ResultsContainer>
          {results.map(result => {
            let isBusy = false;
            const { status, type } = this.getLastTaskForShipment(result.id) || {};
            const icon = this.getCorrectIcon(status, type);

            if (status === 'pending') {
              isBusy = true;
            }

            return (
              <Item
                key={result.id}
                item={result}
                onPrint={this.handlePrint(result)}
                isBusy={isBusy}
                icon={icon}
              />
            );
          })}
        </ResultsContainer>
      );
    };

    return (
      <Base>
        <Drawer
          drawerIsOpen={drawerIsOpen}
          toggleDrawer={this.toggleDrawer}
          items={this.getMenuItems()}
        />
        <MainNavigationBar
          toggleDrawer={this.toggleDrawer}
          cameraIsOpen={cameraIsOpen}
          handleInput={this.handleInput}
          handleKeyUp={this.handleKeyUp}
          onDetected={this.onDetected}
          searchBarRef={this.searchBarRef}
          searchTerm={searchTerm}
          toggleCamera={this.toggleCamera}
        />
        <Body />
        <BottomAppBar
          printSingleResult={printSingleResult}
          handleSingleResultSetting={this.handleSingleResultSetting}
        />
      </Base>
    );
  }
}

RootPage.defaultProps = {
  intl: {},
  results: undefined,
  onSearch: undefined,
  onPrint: undefined,
  history: undefined,
  accessToken: undefined,
  accessTokenIsValid: false,
  pushHistory: undefined,
  selectedPrinterID: null,
  taskQueue: [],
};

RootPage.propTypes = {
  intl: PropTypes.object,
  results: PropTypes.array,
  onSearch: PropTypes.func,
  onPrint: PropTypes.func,
  history: PropTypes.object,
  accessTokenIsValid: PropTypes.bool,
  accessToken: PropTypes.string,
  pushHistory: PropTypes.func,
  selectedPrinterID: PropTypes.string,
  taskQueue: PropTypes.array,
};

const mapDispatchToProps = dispatch => ({
  onSearch: (payload, printSingleResult) => dispatch(searchShipments(payload, printSingleResult)),
  onPrint: documents => dispatch(downloadShipmentDocuments(documents)),
  pushHistory: path => dispatch(push(path)),
});

const mapStateToProps = state => ({
  results: state.shipments.results,
  accessToken: state.authorization.accessToken,
  accessTokenIsValid: state.authorization.accessTokenIsValid,
  selectedPrinterID: state.spooler.selectedPrinterID,
  taskQueue: state.app.taskQueue,
});

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(RootPage);

import {
  APP_ENQUEUE_TASK,
  APP_INITIALIZE,
  APP_INITIALIZE_FAILURE,
  APP_SET_IS_INITIALIZING,
  APP_SET_SERVICE_WORKER_UPDATE_AVAILABLE,
  APP_UPDATE_QUEUED_TASK,
} from './types';

export const initializeApp = () => ({
  type: APP_INITIALIZE,
});

export const setIsInitializing = isInitializing => ({
  type: APP_SET_IS_INITIALIZING,
  isInitializing,
});

export const setInitializationFailure = error => ({
  type: APP_INITIALIZE_FAILURE,
  error,
});

export const setServiceWorkerUpdateAvailable = serviceWorkerUpdateAvailable => ({
  type: APP_SET_SERVICE_WORKER_UPDATE_AVAILABLE,
  serviceWorkerUpdateAvailable,
});

export const enqueueTask = task => ({
  type: APP_ENQUEUE_TASK,
  task,
});

export const updateQueuedTask = task => ({
  type: APP_UPDATE_QUEUED_TASK,
  task,
});

import {
  AUTH_IS_VALIDATING_ACCESS_TOKEN,
  AUTH_SET_ACCESS_TOKEN_IS_VALID,
  AUTH_ACCESS_TOKEN_VALIDATION_FAILURE,
  AUTH_SET_SHOW_ACCESS_TOKEN_MODAL,
  AUTH_SET_ACCESS_TOKEN,
  AUTH_SET_IS_SAVING_ACCESS_TOKEN,
  AUTH_CLEAR_ACCESS_TOKEN,
} from './types';

const initialState = {
  isSavingAccessToken: false,
  isValidatingAccessToken: false,
  accessToken: undefined,
  accessTokenIsValid: false,
  accessTokenValidationError: null,
  accessTokenModalIsOpen: false,
};

export const authorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_IS_VALIDATING_ACCESS_TOKEN:
      return {
        ...state,
        isValidatingAccessToken: action.isValidatingAccessToken,
      };
    case AUTH_SET_ACCESS_TOKEN_IS_VALID:
      return {
        ...state,
        accessTokenIsValid: action.accessTokenIsValid,
      };
    case AUTH_ACCESS_TOKEN_VALIDATION_FAILURE:
      return {
        ...state,
        accessTokenValidationError: action.error,
      };
    case AUTH_SET_SHOW_ACCESS_TOKEN_MODAL:
      return {
        ...state,
        accessTokenModalIsOpen: action.accessTokenModalIsOpen,
      };
    case AUTH_SET_IS_SAVING_ACCESS_TOKEN:
      return {
        ...state,
        isSavingAccessToken: action.isSavingAccessToken,
      };
    case AUTH_SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case AUTH_CLEAR_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: null,
      };
    default:
      return state;
  }
};

export default authorizationReducer;

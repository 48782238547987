import apiService from '../../utils/ApiService';

const { REACT_APP_PICKER_API_URL: PICKER_API_URL } = process.env;

export function getAccessToken() {
  return localStorage.getItem('afostoAccessToken');
}

export function setAccessTokenInStorage(accessToken) {
  localStorage.setItem('afostoAccessToken', accessToken);
}

export function clearAccessTokenInStorage() {
  localStorage.removeItem('afostoAccessToken');
}

export function validateAccessTokenRequest(accessToken) {
  return apiService.get(`${PICKER_API_URL}/info`, {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function saveAccessTokenRequest(accessToken) {
  return validateAccessTokenRequest(accessToken).then(({ headers }) => {
    const spoolerToken = headers['x-spooler-token'];

    if (!spoolerToken) {
      throw new Error('Spooler token not found!');
    }

    return { accessToken, spoolerToken };
  });
}

export default validateAccessTokenRequest;

import {
  AUTH_ACCESS_TOKEN_VALIDATION_FAILURE,
  AUTH_INIT_ACCESS_TOKEN,
  AUTH_IS_VALIDATING_ACCESS_TOKEN,
  AUTH_SAVE_ACCESS_TOKEN,
  AUTH_SET_IS_SAVING_ACCESS_TOKEN,
  AUTH_SET_ACCESS_TOKEN_IS_VALID,
  AUTH_VALIDATE_ACCESS_TOKEN,
  AUTH_ACCESS_TOKEN_SAVE_FAILURE,
  AUTH_SET_SHOW_ACCESS_TOKEN_MODAL,
  AUTH_SET_ACCESS_TOKEN,
  AUTH_CLEAR_ACCESS_TOKEN,
} from './types';

export const validateAccessToken = accessToken => ({
  type: AUTH_VALIDATE_ACCESS_TOKEN,
  accessToken,
});

export const initializeAccessToken = () => ({
  type: AUTH_INIT_ACCESS_TOKEN,
});

export const setIsValidatingAccessToken = isValidatingAccessToken => ({
  type: AUTH_IS_VALIDATING_ACCESS_TOKEN,
  isValidatingAccessToken,
});

export const setAccessTokenIsValid = accessTokenIsValid => ({
  type: AUTH_SET_ACCESS_TOKEN_IS_VALID,
  accessTokenIsValid,
});

export const setAccessTokenValidationFailure = error => ({
  type: AUTH_ACCESS_TOKEN_VALIDATION_FAILURE,
  error,
});

export const saveAccessToken = accessToken => ({
  type: AUTH_SAVE_ACCESS_TOKEN,
  accessToken,
});

export const setIsSavingAccessToken = isSavingAccessToken => ({
  type: AUTH_SET_IS_SAVING_ACCESS_TOKEN,
  isSavingAccessToken,
});

export const setAccessTokenSaveFailure = error => ({
  type: AUTH_ACCESS_TOKEN_SAVE_FAILURE,
  error,
});

export const setShowAccessTokenModal = accessTokenModalIsOpen => ({
  type: AUTH_SET_SHOW_ACCESS_TOKEN_MODAL,
  accessTokenModalIsOpen,
});

export const setAccessToken = accessToken => ({
  type: AUTH_SET_ACCESS_TOKEN,
  accessToken,
});

export const clearAccessToken = () => ({
  type: AUTH_CLEAR_ACCESS_TOKEN,
});

export default validateAccessToken;

import {
  SHIPMENTS_SEARCH_REQUEST,
  SHIPMENTS_DOWNLOAD_DOCUMENTS_REQUEST,
  SHIPMENTS_SET_SEARCH_RESULTS,
  SHIPMENTS_UPDATE_STATUS,
} from './types';

export const searchShipments = (payload, printSingleResult = false) => ({
  type: SHIPMENTS_SEARCH_REQUEST,
  payload,
  printSingleResult,
});

export const downloadShipmentDocuments = documents => ({
  type: SHIPMENTS_DOWNLOAD_DOCUMENTS_REQUEST,
  documents,
});

export const setSearchResults = results => ({
  type: SHIPMENTS_SET_SEARCH_RESULTS,
  results,
});

export const updateShipmentsStatus = ids => ({
  type: SHIPMENTS_UPDATE_STATUS,
  ids,
});

import { defineMessages } from 'react-intl';

const translations = {
  navigation: {
    menu: {
      buttonLabel: {
        id: 'app.navigation.menu.buttonLabel',
        defaultMessage: 'Menu',
        description: 'Label of the menu toggle button',
      },
      items: {
        settings: {
          id: 'app.navigation.menu.items.settings',
          defaultMessage: 'Settings',
          description: 'Settings menu item label',
        },
      },
    },
    search: {
      placeholder: {
        id: 'app.navigation.search.placeholder',
        defaultMessage: 'Search a shipment',
        description: 'Placeholder of the search field',
      },
      buttonLabel: {
        id: 'app.navigation.search.buttonLabel',
        defaultMessage: 'Camera',
        description: 'Label of the camera button',
      },
    },
    filter: {
      buttonLabel: {
        id: 'app.navigation.filter.buttonLabel',
        defaultMessage: 'Filters',
        description: 'Label of the filters button',
      },
    },
  },
  messages: {
    noSearchTerm: {
      id: 'app.messages.noSearchTerm',
      defaultMessage: 'Enter a packing slip number',
      description: 'message when no search term is entered',
    },
    noResultsForSearchTerm: {
      id: 'app.messages.noResultsForSearchTerm',
      defaultMessage: 'No results found',
      description: 'message when no results were found for given search term',
    },
    pressEnterToSearch: {
      id: 'app.messages.pressEnterToSearch',
      defaultMessage: 'Press enter to search',
      description: "message when a searchterm is entered but hasn't pressed enter yet",
    },
    noAccessToken: {
      id: 'app.messages.noAccessToken',
      defaultMessage: 'No access token found',
      description: 'message when there is no access token set',
    },
    accessTokenInvalid: {
      id: 'app.messages.accessTokenInvalid',
      defaultMessage: 'Your access token is invalid',
      description: 'message when the access token is invalid',
    },
    noPrinterSelected: {
      id: 'app.messages.noPrinterSelected',
      defaultMessage: 'No printer selected',
      description: 'message when no spooler printer is selected',
    },
    openSettingsButton: {
      id: 'app.messages.openSettingsButton',
      defaultMessage: 'Open settings',
      description: 'label of the button to show open the settings',
    },
  },
  settings: {
    automaticPrinting: {
      title: {
        id: 'app.settings.automaticPrinting.title',
        defaultMessage: 'Print package label',
        description: 'The label of the package label printing setting',
      },
      description: {
        id: 'app.settings.automaticPrinting.description',
        defaultMessage: 'When only one result',
        description: 'The label of the package label printing setting',
      },
    },
  },
  shipment: {
    status: {
      sent: {
        id: 'app.shipment.status.sent',
        defaultMessage: 'Sent',
        description: 'Shipment sent status label',
      },
      partialsent: {
        id: 'app.shipment.status.partialsent',
        defaultMessage: 'Partially sent',
        description: 'Shipment partialsent status label',
      },
      notsent: {
        id: 'app.shipment.status.notsent',
        defaultMessage: 'Not sent',
        description: 'Shipment sent status label',
      },
      pendingcustomer: {
        id: 'app.shipment.status.pendingcustomer',
        defaultMessage: 'Waiting for customer',
        description: 'Shipment waiting for customer status label',
      },
    },
  },
};

export default defineMessages(translations);

import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';

export const CardListItem = styled('article')`
  && {
    padding: 4px 6px;
  }
`;

export const StatusDisplay = styled(Typography)`
  && {
    color: ${props => props.theme.palette[props.status].main};
  }
`;

export const CardFlexWrapper = styled('div')`
  display: flex;
`;

export const CardButtons = styled(CardActions)`
  && {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const ButtonProgress = styled('div')`
  position: relative;
  margin: ${props => props.theme.spacing(2)}px;
`;

export const IconButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1;
  pointer-events: none;
`;

import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { MainDrawerList } from './styles';

export const NavigationDrawerComponent = ({ drawerIsOpen, toggleDrawer, items }) => (
  <Drawer
    anchor="left"
    open={drawerIsOpen}
    onClose={toggleDrawer(false)}
    onKeyDown={toggleDrawer(false)}
  >
    <MainDrawerList>
      {items.map(({ name, label, onClick }) => (
        <ListItem key={name} button onClick={onClick}>
          <ListItemText primary={label} />
        </ListItem>
      ))}
    </MainDrawerList>
  </Drawer>
);

NavigationDrawerComponent.defaultProps = {
  items: [],
};

NavigationDrawerComponent.propTypes = {
  drawerIsOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  items: PropTypes.array,
};

export default NavigationDrawerComponent;

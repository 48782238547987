import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Quagga from 'quagga';
import ScannerContainer from './styles';
import { isMobileDevice } from '../../utils/device';

class BarcodeScanner extends Component {
  beep = new Audio('/assets/scan_sound.mp3');

  constructor(props) {
    super(props);
    this.state = {
      isSafari: false,
      showDetection: false,
    };
  }

  componentDidMount() {
    const streamLabel = Quagga.CameraAccess.enumerateVideoDevices();
    const dim = this.fixOrientation(500, 200, 0.5);
    streamLabel.then(devices => {
      Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            constraints: {
              width: { min: dim.w },
              height: { min: dim.h },
              facingMode: 'environment',
              aspectRatio: dim.a,
              deviceId: devices[devices.length - 1].deviceId,
            },
          },
          frequency: 20,
          decoder: {
            readers: ['code_39_reader'],
          },
          locator: {
            halfSample: true,
            patchSize: 'medium',
          },
          numOfWorkers: 2,
          locate: true,
        },
        err => {
          if (err) {
            return;
          }
          Quagga.start();
        },
      );
    });

    Quagga.onDetected(this.onDetected);
  }

  componentWillUnmount() {
    Quagga.offDetected(this.onDetected);
    Quagga.stop();
  }

  fixOrientation = (w, h, a) => {
    const md = isMobileDevice();
    const d = { w, h, a };
    if (md && window.matchMedia('(orientation:portrait)').matches) {
      if (navigator.userAgent.match(/Safari/i) && !navigator.userAgent.match(/Chrome/i)) {
        this.setState({ isSafari: true });
        d.w = 640;
        d.h = 480;
        d.a = { min: 0, max: 1 };
      } else {
        this.setState({ isSafari: false });
        d.w = h;
        d.h = w;
        d.a = a;
      }
    }

    return d;
  };

  onDetected = result => {
    const { onDetected } = this.props;
    const { code } = result.codeResult;

    if (this.lastResult !== code) {
      this.beep.play();
      this.lastResult = code;
      this.setState({ showDetection: true });
      setTimeout(() => this.setState({ showDetection: false }), 500);
      onDetected(result);
    }
  };

  render() {
    const { isSafari, showDetection } = this.state;

    return (
      <ScannerContainer
        id="interactive"
        className={classNames('viewport', {
          'scanner-safari-fix': isSafari,
          'detected-barcode': showDetection,
        })}
      />
    );
  }
}

BarcodeScanner.propTypes = {
  onDetected: PropTypes.func.isRequired,
};

export default BarcodeScanner;

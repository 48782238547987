import React from 'react';
import { NavigationBarBar, NavigationBarContainer } from './styles';

const NavigationBar = props => (
  <NavigationBarContainer>
    <NavigationBarBar elevation={1} {...props} />
  </NavigationBarContainer>
);

export default NavigationBar;

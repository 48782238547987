import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { appReducer } from './modules/app/reducers';
import { shipmentsReducer } from './modules/shipments/reducers';
import { authorizationReducer } from './modules/authorization/reducers';
import { spoolerReducer } from './modules/spooler/reducers';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    shipments: shipmentsReducer,
    authorization: authorizationReducer,
    spooler: spoolerReducer,
  });

export default rootReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import translations from '../translations';
import { BottomFixedAppBar } from './styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';

const BottomAppBar = ({ handleSingleResultSetting, printSingleResult, intl }) => (
  <BottomFixedAppBar color="inherit" position="fixed" elevation={10}>
    <List disablePadding>
      <ListItem button onClick={handleSingleResultSetting}>
        <ListItemText
          primary={intl.formatMessage(translations.settings.automaticPrinting.title)}
          secondary={intl.formatMessage(translations.settings.automaticPrinting.description)}
        />
        <ListItemSecondaryAction>
          <Switch
            checked={printSingleResult}
            onChange={handleSingleResultSetting}
            inputProps={{
              'aria-label': intl.formatMessage(translations.settings.automaticPrinting.title),
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  </BottomFixedAppBar>
);

BottomAppBar.propTypes = {
  handleSingleResultSetting: PropTypes.func.isRequired,
  printSingleResult: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(BottomAppBar);

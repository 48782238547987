import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';

export const Base = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: ${props => props.theme.spacing(12)}px;
`;

export const ResultsContainer = styled('div')`
  height: 100%;
`;

export const MessageContainer = styled('div')`
  padding: 3rem 1rem 1rem;
  text-align: center;
`;

export const SuccessCheckIcon = styled(CheckIcon)`
  && {
    color: ${props => props.theme.palette.success.main};
  }
`;

import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const NavigationBarContainer = styled('div')`
  && {
    padding: 6px;
    @media (min-width: ${props => props.theme.breakpoints.values.md}px) {
      padding: 0;
    }
  }
`;
export const NavigationBarBar = styled(Paper)`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px 4px;
    @media (min-width: ${props => props.theme.breakpoints.values.md}px) {
      padding: 4px 12px;
      border-radius: 0;
    }
  }
`;

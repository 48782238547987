import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import nl from 'react-intl/locale-data/nl';
import { ThemeProvider } from 'styled-components';
import Router from './Router';
import messages from './translations';
import { flattenMessages } from './utils/translation';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import { connect } from 'react-redux';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { initializeApp } from './modules/app/actions';
import { getServiceWorker } from './serviceWorker';
import UpdateAvailable from './components/UpdateAvailable/UpdateAvailable';

const locale = 'nl';
export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
    success: {
      main: green['500'],
      dark: green['800'],
      light: green['200'],
      contrastText: '#fff',
    },
    warning: {
      main: orange['400'],
      dark: orange['800'],
      light: orange['200'],
      contrastText: '#fff',
    },
  },
});

addLocaleData([...nl, ...en]);

if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
  LogRocket.init('5mrofq/afosto-picker');
  setupLogRocketReact(LogRocket);
}

class App extends Component {
  componentDidMount() {
    const { onInit } = this.props;
    onInit();
  }

  reload = () => {
    const serviceWorker = getServiceWorker();

    if (serviceWorker) {
      serviceWorker.waiting.postMessage('skipWaiting');
    }
  };

  render() {
    const { serviceWorkerUpdateAvailable } = this.props;

    return (
      <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
        <>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <ThemeProvider theme={theme}>
              <Router />
            </ThemeProvider>
          </MuiThemeProvider>
          <UpdateAvailable onClick={this.reload} updateAvailable={serviceWorkerUpdateAvailable} />
        </>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  onInit: PropTypes.func,
  serviceWorkerUpdateAvailable: PropTypes.bool,
};

App.defaultProps = {
  onInit: undefined,
  serviceWorkerUpdateAvailable: false,
};

const mapStateToProps = state => ({
  serviceWorkerUpdateAvailable: state.app.serviceWorkerUpdateAvailable,
});

const mapDispatchToProps = dispatch => ({
  onInit: () => dispatch(initializeApp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

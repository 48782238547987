import { delay } from 'redux-saga';
import { all, fork, call, put, take, takeLatest } from 'redux-saga/effects';
import {
  AUTH_VALIDATE_ACCESS_TOKEN,
  AUTH_INIT_ACCESS_TOKEN,
  AUTH_SAVE_ACCESS_TOKEN,
  AUTH_CLEAR_ACCESS_TOKEN,
} from './types';
import {
  clearAccessTokenInStorage,
  getAccessToken,
  saveAccessTokenRequest,
  setAccessTokenInStorage,
  validateAccessTokenRequest,
} from './functions';
import {
  setAccessToken,
  setAccessTokenIsValid,
  setAccessTokenSaveFailure,
  setAccessTokenValidationFailure,
  setIsSavingAccessToken,
  setIsValidatingAccessToken,
  setShowAccessTokenModal,
} from './actions';
import { setSpoolerAccessToken } from '../spooler/functions';

export function* handleAccessTokenValidation({ accessToken }) {
  try {
    yield call(delay, 500);
    yield put(setIsValidatingAccessToken(true));
    yield call(validateAccessTokenRequest, accessToken);
    yield put(setAccessTokenIsValid(true));
    yield put(setIsValidatingAccessToken(false));
  } catch (err) {
    yield put(setAccessTokenIsValid(false));
    yield put(setAccessTokenValidationFailure(err.message));
    yield put(setIsValidatingAccessToken(false));
  }
}

export function* accessTokenValidationFlow() {
  yield takeLatest(AUTH_VALIDATE_ACCESS_TOKEN, handleAccessTokenValidation);
}

export function* handleSaveAccessToken({ accessToken }) {
  try {
    yield put(setIsSavingAccessToken(true));
    const { accessToken: token, spoolerToken } = yield call(saveAccessTokenRequest, accessToken);
    yield call(setAccessTokenInStorage, token);
    yield call(setSpoolerAccessToken, spoolerToken);
    yield put(setAccessToken(token));
    yield put(setIsSavingAccessToken(false));
    yield put(setShowAccessTokenModal(false));
  } catch (err) {
    yield put(setIsSavingAccessToken(false));
    yield put(setAccessTokenSaveFailure(err.message));
  }
}

export function* saveAccessTokenFlow() {
  while (true) {
    const payload = yield take(AUTH_SAVE_ACCESS_TOKEN);
    yield call(handleSaveAccessToken, payload);
  }
}

export function* handleInitializeAccessToken() {
  try {
    const accessToken = yield call(getAccessToken);

    if (accessToken) {
      const { spoolerToken } = yield call(saveAccessTokenRequest, accessToken);
      yield call(setSpoolerAccessToken, spoolerToken);
      yield put(setAccessTokenIsValid(true));
      yield put(setAccessToken(accessToken));
    }
  } catch (err) {
    yield put(setAccessTokenValidationFailure(err.message));
  }
}

export function* initializeAccessTokenFlow() {
  while (true) {
    yield take(AUTH_INIT_ACCESS_TOKEN);
    yield call(handleInitializeAccessToken);
  }
}

export function* clearAccessTokenFlow() {
  while (true) {
    yield take(AUTH_CLEAR_ACCESS_TOKEN);
    yield call(clearAccessTokenInStorage);
  }
}

export function* authorizationSaga() {
  const sagas = [
    initializeAccessTokenFlow,
    accessTokenValidationFlow,
    saveAccessTokenFlow,
    clearAccessTokenFlow,
  ];
  yield all(sagas.map(saga => fork(saga)));
}

export default authorizationSaga;

/* eslint-disable no-underscore-dangle */

import axios from 'axios';

class ApiService {
  constructor() {
    this._axios = axios.create({
      headers: {},
    });
  }

  get axios() {
    const token = localStorage.getItem('afostoAccessToken');
    this._axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return this._axios;
  }

  get(path, options = {}) {
    return this.axios.get(path, options);
  }

  put(path, data, options = {}) {
    return this.axios.put(path, data, options);
  }

  post(path, data, options = {}) {
    return this.axios.post(path, data, options);
  }

  delete(path, options = {}) {
    return this.axios.delete(path, options);
  }
}

const apiService = new ApiService();

export default apiService;

import { SHIPMENTS_SET_SEARCH_RESULTS, SHIPMENTS_UPDATE_STATUS } from './types';

export const initialState = {
  results: [],
};

export const shipmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHIPMENTS_SET_SEARCH_RESULTS:
      return {
        ...state,
        results: action.results,
      };
    case SHIPMENTS_UPDATE_STATUS:
      return {
        ...state,
        results: state.results.reduce((acc, current) => {
          const shipment = current;

          if (action.ids.includes(current.id)) {
            shipment.status = 'sent';
          }

          acc.push(shipment);
          return acc;
        }, []),
      };
    default:
      return state;
  }
};

export default shipmentsReducer;

import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';

export const AppHeader = styled(AppBar)`
  && {
    background-color: transparent;
  }
`;

export const NavigationBarInnerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NavigationBarGroup = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const BottomFixedAppBar = styled(AppBar)`
  && {
    bottom: 0;
    top: auto;
  }
`;

export const MainDrawerList = styled(List)`
  && {
    width: 250px;
  }
`;

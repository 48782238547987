import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { register as registerServiceWorker } from './serviceWorker';
import store from './store';
import { setServiceWorkerUpdateAvailable } from './modules/app/actions';
import 'typeface-roboto';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
registerServiceWorker({
  onUpdate() {
    store.dispatch(setServiceWorkerUpdateAvailable(true));
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { injectIntl, intlShape } from 'react-intl';
import translations from './translations';

const UpdateAvailable = ({ updateAvailable, onClick, intl }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={updateAvailable}
    message={<span>{intl.formatMessage(translations.messages.updateAvailableMessage)}</span>}
    action={[
      <Button key="undo" color="secondary" size="small" onClick={onClick}>
        {intl.formatMessage(translations.messages.updateButtonAction)}
      </Button>,
    ]}
  />
);

UpdateAvailable.defaultProps = {
  onClick: undefined,
  updateAvailable: false,
};

UpdateAvailable.propTypes = {
  intl: intlShape.isRequired,
  onClick: PropTypes.func,
  updateAvailable: PropTypes.bool,
};

export default injectIntl(UpdateAvailable);

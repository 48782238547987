import { all, call, fork, put, take, spawn } from 'redux-saga/effects';
import { SHIPMENTS_SEARCH_REQUEST, SHIPMENTS_DOWNLOAD_DOCUMENTS_REQUEST } from './types';
import { downloadDocumentsForShipments, searchForShipments } from './functions';
import { downloadShipmentDocuments, setSearchResults, updateShipmentsStatus } from './actions';
import { printAttachments } from '../spooler/actions';
import { getSelectedHubID, getSelectedPrinterID } from '../spooler/functions';
import { enqueueTask, updateQueuedTask } from '../app/actions';
import { TASK_LOG_TYPE_ERROR, TASK_LOG_TYPE_SUCCESS, TASK_STATUS_SUCCESS } from '../app/constants';
import { addTaskLog, createTask } from '../app/functions';

export function* handleShipmentsSearch({ payload, printSingleResult }) {
  let task = {
    ...createTask('search'),
    payload: {
      query: { ...payload },
    },
  };

  try {
    yield put(enqueueTask(task));
    const results = yield call(searchForShipments, payload);
    yield put(setSearchResults(results));

    if (results.length === 1 && printSingleResult) {
      task = yield call(addTaskLog, task, {
        type: TASK_LOG_TYPE_SUCCESS,
        message: `Shipments found for ${payload.q}`,
      });
      task.status = TASK_STATUS_SUCCESS;
      yield put(updateQueuedTask(task));

      const documents = results.map(({ id }) => ({
        shipment_id: id,
        type: 'label',
      }));
      yield put(downloadShipmentDocuments(documents));
    } else if (results.length < 1) {
      task = yield call(addTaskLog, task, {
        type: TASK_LOG_TYPE_ERROR,
        message: `No shipments found for ${payload.q}!`,
      });
      yield put(updateQueuedTask(task));
    } else {
      task = yield call(addTaskLog, task, {
        type: TASK_LOG_TYPE_SUCCESS,
        message: `Shipments found for ${payload.q}`,
      });
      task.status = TASK_STATUS_SUCCESS;
      yield put(updateQueuedTask(task));
    }
  } catch (err) {
    task = yield call(addTaskLog, task, {
      type: TASK_LOG_TYPE_ERROR,
      message: `An error occurred: ${err.message}`,
    });
    yield put(updateQueuedTask(task));
  }
}

export function* handleDownloadDocuments({ documents }) {
  const shipments = documents.map(({ shipment_id: key }) => ({ type: 'shipment', key }));
  const shipmentIds = documents.map(({ shipment_id: id }) => id);
  let task = {
    ...createTask('download'),
    context: [...shipments],
    payload: { documents },
  };

  try {
    yield put(enqueueTask(task));
    const response = yield call(downloadDocumentsForShipments, documents);
    const attachments = response.map(attachment => ({ url: attachment.url }));

    if (attachments.length) {
      task = yield call(addTaskLog, task, {
        type: TASK_LOG_TYPE_SUCCESS,
        message: `Documents downloaded for shipments: ${shipmentIds.join(', ')}`,
      });
      task.status = TASK_STATUS_SUCCESS;
      yield put(updateQueuedTask(task));

      const printerID = yield call(getSelectedPrinterID);
      const hubID = yield call(getSelectedHubID);
      const ids = response.reduce((current, attachment) => {
        current.push(attachment.shipment_id);
        return current;
      }, []);
      yield put(updateShipmentsStatus(ids));
      const description = `Printing documents for shipments: ${ids.join(', ')}`;

      const printTask = {
        ...createTask('print'),
        context: ids.map(id => ({ type: 'shipment', key: id })),
      };
      yield put(enqueueTask(printTask));

      yield put(
        printAttachments({
          attachments,
          description,
          key: printTask.id,
          printerID,
          hubID,
        }),
      );
    } else {
      task = yield call(addTaskLog, task, {
        type: TASK_LOG_TYPE_ERROR,
        message: `Documents could not be downloaded for shipments: ${shipmentIds.join(', ')}`,
      });
      yield put(updateQueuedTask(task));
    }
  } catch (err) {
    task = yield call(addTaskLog, task, {
      type: TASK_LOG_TYPE_ERROR,
      message: `An error occurred: ${err.message}`,
    });
    yield put(updateQueuedTask(task));
  }
}

export function* shipmentsSearchFlow() {
  while (true) {
    const data = yield take(SHIPMENTS_SEARCH_REQUEST);
    yield spawn(handleShipmentsSearch, data);
  }
}

export function* shipmentsDownloadDocumentsFlow() {
  while (true) {
    const data = yield take(SHIPMENTS_DOWNLOAD_DOCUMENTS_REQUEST);
    yield fork(handleDownloadDocuments, data);
  }
}

export function* shipmentsSaga() {
  const sagas = [shipmentsSearchFlow, shipmentsDownloadDocumentsFlow];
  yield all(sagas.map(saga => fork(saga)));
}

export default shipmentsSaga;

import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
  CardListItem,
  CardFlexWrapper,
  CardButtons,
  IconButtonProgress,
  ButtonProgress,
} from './styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';
import translations from '../../pages/Root/translations';
import ShipmentStatus from './ShipmentStatus/ShipmentStatus';

const Item = ({ item, onPrint, isBusy, icon }) => (
  <CardListItem>
    <Card elevation={1}>
      <CardFlexWrapper>
        <Grid item xs={12}>
          <CardContent>
            <Typography component="div" variant="h6">
              {item.reference}
            </Typography>
            <Typography color="textSecondary">
              {item.address.street} {item.address.house_number} {item.address.house_number_suffix}
              {', '} {item.address.zip_code}, {item.address.city}
            </Typography>
            <ShipmentStatus status={item.status}>
              {translations.shipment.status[item.status] ? (
                <FormattedMessage {...translations.shipment.status[item.status]} />
              ) : (
                item.status
              )}
            </ShipmentStatus>
          </CardContent>
        </Grid>
        <CardButtons>
          <ButtonProgress>
            <IconButton color="primary" onClick={onPrint} disabled={isBusy}>
              {icon}
            </IconButton>
            {isBusy && <IconButtonProgress size={60} thickness={1.5} />}
          </ButtonProgress>
        </CardButtons>
      </CardFlexWrapper>
    </Card>
  </CardListItem>
);

Item.propTypes = {
  item: PropTypes.object.isRequired,
  onPrint: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  isBusy: PropTypes.bool,
};

Item.defaultProps = {
  isBusy: false,
};

export default Item;

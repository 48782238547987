import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';

const NavigationBarSearch = styled(InputBase)`
  && {
    flex: 1;
    margin-left: 8px;
  }
`;

export default NavigationBarSearch;

import apiService from '../../utils/ApiService';

const { REACT_APP_PICKER_API_URL: PICKER_API_URL } = process.env;

export function searchForShipments(payload = {}) {
  return apiService
    .get(`${PICKER_API_URL}/shipments`, {
      params: { ...payload },
    })
    .then(({ data }) => data);
}

export function downloadDocumentsForShipments(payload = []) {
  return apiService
    .post(`${PICKER_API_URL}/shipments/download`, {
      documents: payload,
    })
    .then(({ headers, data }) => {
      const spoolerToken = headers['x-spooler-token'];

      if (!spoolerToken) {
        throw new Error('No spooler token found!');
      }

      localStorage.setItem('spoolerAccessToken', spoolerToken);
      return data;
    });
}

import { all, fork, call, put, take, select } from 'redux-saga/effects';
import {
  SPOOLER_PRINT_ATTACHMENT_JOB,
  SPOOLER_GET_PRINTERS,
  SPOOLER_SET_SELECTED_PRINTER,
  SPOOLER_INIT_PRINTER_SELECTION,
  SPOOLER_UPDATE_PRINT_JOB_STATUS,
} from './types';
import { AUTH_SET_ACCESS_TOKEN } from '../authorization/types';
import {
  createAttachmentJob,
  getPrintersRequest,
  getSelectedHubID,
  getSelectedPrinterID,
  setSelectedPrinterRequest,
  printJob,
  addActiveJobs,
} from './functions';
import {
  setGetPrintersFailure,
  setIsGettingPrinters,
  setPrinters,
  setSelectedPrinter,
  setSelectedPrinterFailure,
  setActiveJobs,
  setUpdatePrintJobStatusFailure,
  startPrintJob,
  setPrintJobError,
  updatePrintJob,
} from './actions';

export const activeJobsSelector = state => state.spooler.activeJobs;

export function* handlePrintersRequest() {
  try {
    yield put(setIsGettingPrinters(true));
    const printers = yield call(getPrintersRequest);
    yield put(setPrinters(printers));
    yield put(setIsGettingPrinters(false));
  } catch (err) {
    yield put(setIsGettingPrinters(false));
    yield put(setGetPrintersFailure(err.message));
  }
}

export function* handleSetSelectedPrinter({ payload }) {
  try {
    yield call(setSelectedPrinterRequest, payload);
  } catch (err) {
    yield put(setSelectedPrinterFailure(err.message));
  }
}

export function* getPrintersFlow() {
  while (true) {
    yield take(SPOOLER_GET_PRINTERS);
    yield call(handlePrintersRequest);
  }
}

export function* setSelectedPrinterFlow() {
  while (true) {
    const data = yield take(SPOOLER_SET_SELECTED_PRINTER);
    yield call(handleSetSelectedPrinter, data);
  }
}

export function* handlePrintAttachmentJob({ payload }) {
  const { key, hubID, printerID } = payload;
  const devices = [printerID];
  let job = {
    key,
    reference: null,
    status: 'queued',
    devices,
    hub: hubID,
    errors: [],
  };

  try {
    const attachmentJob = yield call(createAttachmentJob, payload.attachments, devices);

    if (attachmentJob !== null) {
      yield put(startPrintJob(job));
      const response = yield call(
        printJob,
        payload.hubID,
        attachmentJob,
        payload.description,
        'shipping_label',
      );
      const { reference, status } = response;

      job = { ...job, reference, status };
      yield put(updatePrintJob(job));

      const activeJob = [{ ...job }];
      const activeJobs = yield select(activeJobsSelector);
      yield put(setActiveJobs([...activeJobs, ...activeJob]));
      yield call(addActiveJobs, [...activeJob]);
    }
  } catch (err) {
    job.errors.push(err.message);
    yield put(setPrintJobError(job));
  }
}

export function* printAttachmentJobFlow() {
  while (true) {
    const data = yield take(SPOOLER_PRINT_ATTACHMENT_JOB);
    yield call(handlePrintAttachmentJob, data);
  }
}

export function* handleInitializePrinterSelection() {
  try {
    const selectedPrinterID = yield call(getSelectedPrinterID);
    const selectedHubID = yield call(getSelectedHubID);

    if (selectedPrinterID && selectedHubID) {
      yield put(setSelectedPrinter({ selectedPrinterID, selectedHubID }));
    }
  } catch (err) {
    yield put(setSelectedPrinterFailure(err.message));
  }
}

export function* initializePrinterSelectionFlow() {
  while (true) {
    yield take(SPOOLER_INIT_PRINTER_SELECTION);
    yield call(handleInitializePrinterSelection);
  }
}

export function* initializePrintersFlow() {
  while (true) {
    const { accessToken } = yield take(AUTH_SET_ACCESS_TOKEN);

    if (accessToken) {
      yield call(handlePrintersRequest);
    } else {
      yield put(setPrinters([]));
      yield put(setSelectedPrinter({ selectedPrinterID: null, selectedHubID: null }));
    }
  }
}

export function* handleUpdatePrintJobStatus({ job }) {
  try {
    const { reference, status } = job;
    yield put(updatePrintJob({ ...job }));

    const activeJobs = yield select(activeJobsSelector);
    const newActiveJobs = activeJobs.reduce((acc, current) => {
      let activeJob = current;

      if (activeJob.reference === reference) {
        activeJob = { ...activeJob, status };
      }

      acc.push(activeJob);

      return acc;
    }, []);

    yield put(setActiveJobs(newActiveJobs));
  } catch (err) {
    yield put(setUpdatePrintJobStatusFailure(err.message));
  }
}

export function* updatePrintJobStatusFlow() {
  while (true) {
    const data = yield take(SPOOLER_UPDATE_PRINT_JOB_STATUS);
    yield call(handleUpdatePrintJobStatus, data);
  }
}

export function* spoolerSaga() {
  const sagas = [
    initializePrinterSelectionFlow,
    initializePrintersFlow,
    getPrintersFlow,
    setSelectedPrinterFlow,
    printAttachmentJobFlow,
    updatePrintJobStatusFlow,
  ];

  yield all(sagas.map(saga => fork(saga)));
}

export default spoolerSaga;
